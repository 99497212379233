import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

// CSS
import 'swiper/swiper-bundle.css'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'
import Plaatjie from '@ubo/plaatjie'

SwiperCore.use([Autoplay])

const Wrapper = styled.section`
  & .swiper-container {
    padding: 1rem 0;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 576px) {
    height: auto;
  }
  @media (max-width: 575px) {
    height: 50px;
  }
  @media (max-width: 375px) {
    height: 40px;
  }
`

const StyledBlockDefault = styled(BlockDefault)`
  & p {
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }

  @media (min-width: 991px) {
    min-width: 200px;
    min-height: 200px;
  }
  @media (max-width: 767px) {
    min-width: 145px;
    min-height: 145px;
  }
  @media (max-width: 575px) {
    min-width: 130px;
    min-height: 130px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.post} !important;
    }
  }
`

interface KnowledgeSliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
  location: any
}

const KnowledgeSlider: React.FC<KnowledgeSliderProps> = ({
  fields,
  location,
}) => {
  const delay = fields.delay ? fields.delay * 1000 : 5000

  return (
    <Wrapper className="mb-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <Content content={fields.description} className="mb-5" />
            <Swiper
              autoplay={{ delay }}
              spaceBetween={-70}
              freeMode
              breakpoints={{
                1: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 3,
                },
                991: {
                  slidesPerView: 4,
                },
                1199: {
                  slidesPerView: 5,
                },
              }}
            >
              {fields?.knowledgeslider
                ?.filter(
                  (slide) =>
                    !location.pathname
                      .replace(/-/g, ' ')
                      .includes(slide?.slide?.link?.title?.toLowerCase())
                )
                .map((slide, index: number) => (
                  <SwiperSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <NavLink to={slide?.slide?.link?.url || '/'}>
                      <StyledBlockDefault className="justify-content-around">
                        <Image image={slide?.slide?.icon} alt="" />
                        <p className="mb-0 font-weight-bold">
                          {slide?.slide?.link?.title}
                        </p>
                      </StyledBlockDefault>
                    </NavLink>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default KnowledgeSlider
