import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

// CSS
import 'swiper/swiper-bundle.css'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

SwiperCore.use([Autoplay])

const Wrapper = styled.section`
  & a:hover {
    opacity: 0.8;
    & img {
      filter: unset;
    }
  }
`

const Image = styled(Plaatjie)`
  max-width: 180px;
  max-height: 120px;
  object-fit: contain;
  filter: grayscale(100%);

  object-position: center center;

  object-position: center;

  @media (min-width: 576px) {
    height: 120px;
  }
  @media (max-width: 575px) {
    height: 100px;
    width: 150px;
  }
  @media (max-width: 375px) {
    height: 70px;
    width: 120px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.large} !important;
    }
  }
`

interface ClientSliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
}

const ClientSlider: React.FC<ClientSliderProps> = ({ fields }) => {
  const delay = fields.delay ? fields.delay * 1000 : 3000

  return (
    <Wrapper className="mb-5">
      <div className="container py-5">
        <Content className="mb-4" content={fields.description} />
        <Swiper
          className="ms-sm-5"
          autoplay={{ delay }}
          breakpoints={{
            1: {
              slidesPerView: 2,
            },
            375: {
              slidesPerView: 2,
            },
            767: {
              slidesPerView: 3,
            },
            991: {
              slidesPerView: 4,
            },
          }}
          centerInsufficientSlides
        >
          {fields?.clientslider?.map((slide, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {slide?.slide?.link ? (
                <SwiperSlide className="d-flex justify-content-center">
                  <a
                    href={slide.slide.link.url}
                    className="swiper-slide d-flex justify-content-center align-items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      objectFit="contain"
                      objectPosition="center"
                      image={slide?.slide?.image}
                      alt=""
                    />
                  </a>
                </SwiperSlide>
              ) : (
                <SwiperSlide className="d-flex justify-content-center">
                  <Image image={slide?.slide?.image} alt="" />
                </SwiperSlide>
              )}
            </React.Fragment>
          ))}
        </Swiper>
      </div>
    </Wrapper>
  )
}

export default ClientSlider
